import React from 'react'
import PropTypes from 'prop-types'
import { Button, Space } from '@pyt/micros'
import logoutImage from '../images/logoutImage.gif'

// const userInLocal = localStorage.getItem('persist:root') || "{}";
// const data = JSON.parse((userInLocal || {})?.user) || "{}";
// const userDetails = userInLocal ? JSON.parse(data) : {};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
  componentDidMount() {}

  render() {
    if (this.state.hasError) {
      return (
        <Space
          padding={24}
          gutter={24}
          alignItems="center"
          justifyContent="center"
          width="100vw"
          height="100vh"
          direction="column"
        >
          <Space.Item position="absolute" top={0} right={0} padding={16}>
            <Button
              onPress={() => {
                window.open('/admin/default/logout', '_self')
              }}
            >
              Logout
            </Button>
          </Space.Item>

          {/* <strong>Error 500</strong>
          <p>Something went wrong.</p> */}
          <img src={logoutImage} />
          {/* <strong>{userDetails?.user}</strong> */}
        </Space>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
}

export default ErrorBoundary
